header {
	background: transparent;
	padding: 0;

	.header-one {
		background: $zuccini;
		overflow: visible;

		.container {
			position: relative;
			max-width: 1220px;
			padding: 0 30px !important;
		}

		.area-one {
			display: flex;
			justify-content: flex-end;
			align-items: stretch;
			width: 100%;
  		max-width: 800px;
  		float: right;
  		height: 47px;
  		opacity: 1;
  		transition: $transition--75;

  		@include MQ__tablet--small {
				display: none;
			}

  		&.shrink {
			  // opacity: 0;
  			height: 0;

  			a.facebook {
  				height: 0;
  			}
  		}
		}

		p {
			color: $white;
			display: flex;
		  align-items: center;
		  flex-wrap: nowrap;
		  margin-bottom: 0;
		  font-size: 17px;
		  display: block;

		  &.phone {
		  	margin-left: 20px;
		  	a {
		  		background: url(/sites/barjo.co.uk/files/phone.png) no-repeat transparent;
		  		padding: 8px 0 8px 25px;
					background-position: left 50%;
			    margin-top: 5px;
    			float: left;

    			&:hover {
    				cursor: pointer;
    				text-decoration: none;
    			}
		  	}
		  }

		  &.mast-desc {
		  	margin-right: 23px;
		  	margin-top: 14px;

		  	@include MQ__1024 {
					display: none;
				}
		  }
		}

		a {
			color: $white;
			font-size: 18px;
			letter-spacing: 0;
			text-transform: uppercase;

			&.facebook {
				display: block;
				margin-top: 7px;
				margin-left: 16px;
				//transition: all 0s ease !important;
				z-index: 0;
				display: inline-block;
  			overflow: hidden;
  			height: 33px;
  			transition: 0.2s ease all;

				img {
					//transition: 0s !important;
				}
			}
		}
	}

	.header-two {
		background: $white;
		transition: $transition;
		box-shadow: 0px; 

		.container {
			max-width: unset;

			@include MQ__tablet {
				padding: 10px 0px !important;
			}

			@include MQ__tablet--small {
				padding: 12px 0px !important;
			}
		}

		&.shadow {
			box-shadow: 0px 0px 7px 0px 
		}
	}

	.header-logo {
		width: 265px;
		position: absolute;
		left: 3px;
		top: 16px;
		z-index: 9;
		transition: $transition--75;

		@include MQ__tablet {
			width: 210px;
		}

		@include MQ__tablet--small {
			width: 131px;
			top: 12px;
			left: 15px;
			z-index: 99999;
		}

		img {
			width: 100%;
			height: auto;
			max-width: unset;
		}

		&.shrink {
			width: 147px;
			top: 12px;
		}
	}
}