.page-content {
	width: 100%;
	float: left;
}

.page-catalog {

	#page-header {
		margin-bottom: 30px !important;
	}

	#main-content {
		margin-bottom: 0px;
	}

	.page-content .container {
	  // padding: 0 12px;
	}

	.view-uc-catalog .buttons {
		a  {
			width: 50%;
		}

		form {
			width: 50%;
		}
	}
}