// ==========================================================================
// Base Styles
// ==========================================================================

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0 !important;
}

body {
  font-size: $base__font-size;
  font-family: $font-family--secondary;
  font-weight: $fw--book;
  line-height: $base__line-height;
  letter-spacing: $letter-space;
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1250px;
  padding: $padding;

  @include MQ__tablet--small {
    padding: 0 15px;
  }
}

// .site {
//   position: relative;
//   display: flex;
//   min-height: 100vh;
//   flex-direction: column;
//   overflow: hidden;
// }

.page-section {
  width: 100%;
}

main {
  display: block;
}

section {
  width: 100%;
  float: none;
  margin: 0 auto;
}

// Headings

h1, h2, h3, h4, h5, h6 {
    font-family: $font-family--primary;
}

h1 {
  margin: 0;
  font-size: 33px;
  font-weight: $fw--book;
}

h2 {
  margin: 0 0 20px 0;
  font-size: 28px;
  font-weight: $fw--book;
}

h3 {
  margin: 0;
  font-size: 24px;
  line-height: 1;
  font-weight: $fw--book;
}

h4 {
  margin: 0;
  font-size: 18px;
  line-height: 1;
  font-weight: $fw--book;
}

// Text
p, #page-content p {
  margin: 0 0 20px 0;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  color: $offblack;

  @include MQ__1200 {
    font-size: 16px;
  }

  @include MQ__mobile--large {
    font-size: 15px;
    line-height: 1.2;
  }
}

.field-name-body p {
  font-size: 18px;
  letter-spacing: .1px;
  padding-left: 5px;
  margin-bottom: 20px;
  line-height: 26px;

  @include MQ__1200 {
    font-size: 16px;
  }

  @include MQ__mobile--large {
    font-size: 15px;
    line-height: 1.2;
  }
}

a.button-stnd {
  @extend %button-stnd;
}

// Lists

ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li p {
  margin-bottom: 0;
}

// Images

img {
  display: block;
}

.menu-lamp ul, li li {
  width: auto;
}

// Links
a {
  text-decoration: none;
  outline: none;
  color: $jewel;

  &:hover {
    text-decoration: underline;
  }
}

// Table (on cookie policy)
table {
  border-collapse: separate;
  border-spacing: 1px;
}

td, th {
  padding: 5px;
}

.cookie-policy {
  overflow: visible;
}

#main-content {
  background: $white;
  width: 100%;
  float: left;
  padding: 0;
  margin-bottom: 25px;
}
section {
  width: 100%;
  float: left;
}

.flexiframe {
  position: relative;
  padding-bottom: 76.25%;
  padding-top: 25px;
  height: 0;
  margin-bottom: 30px;
  width: 100%;
  float: left;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

abbr {
  border-bottom: 0 !important;
  text-decoration: none !important;
}

.page-node-3472,
.page-node-3476 {
  .view-product-filter form,
  a#barjo-show-products,
  .view-product-filter .view-footer {
    display: none;
  }

  .view-product-filter .view-filters .titles {
    margin: 0;
  }

}