// ==========================================================================
// Extends
// ==========================================================================

%button-stnd {
  background: #075127;
  color: #fff;
  display: inline-block;
  padding: 12px 19px;
  font-size: 20px;
  font-weight: 500;
  outline: 0 !important;

  &:hover {
    background: #14723c;
    text-decoration: none;
  }
}

%button-grey {
	background: $bonjour;
	text-align: center;
	color: $offblack;
	font-size: 15px !important;
  padding: 0px 5px 0px;
  padding: 7px 12px;
  outline: 0 !important;

  &:hover {
    color: $offblack;
  }
}

%cart-button {
	background: $fuel;
	text-transform: none;
	text-align: center;
	font-size: 15px !important;
	margin: 0;
  padding: 7px 12px;
  outline: 0 !important;
  font-weight: 600 !important;
}

%cart-button-lg {
	background: $fuel;
	text-transform: none;
	text-align: center;
	font-size: 20px !important;
	margin: 0;
	color: $black;
	font-weight: 500 !important;
  outline: 0 !important;

  &:active,
  &:focus {
    text-decoration: none;
  }

  @media screen and (max-width: 500px) {
    font-size: 16px !important;
  } 
}

%cart-button-lg-green {
  background: $jewel;
  text-transform: none;
  text-align: center;
  font-size: 20px !important;
  margin: 0;
  color: $white;
  font-weight: 500 !important;
  outline: 0 !important;

  &:hover {
    background: $zuccini;
  }

  @media screen and (max-width: 500px) {
    font-size: 16px !important;
  } 
}

// ====================================================
// Learn more button
// ====================================================

%button-arrow {
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  font-size: 18px;
  line-height: 1;
  color: $white;
  text-decoration: none;
  border-radius: $br--default;
  overflow: hidden;
  border: 2px solid $black;
  padding: 10px 25px 10px 25px;
  border-radius: 5px;
  transition: $transition;
  width: 139px;
  outline: 0 !important;

  &::after {
    background-image: url(/sites/barjo.co.uk/files/chev-right_1.png);
    background-repeat: no-repeat;
    background-size: 8px;
    content: "";
    width: 8px;
    height: 17px;
    position: absolute;
    right: 13px;
    margin-top: -20px;
    transition: $transition;
    opacity: 0;
  }

  &:hover {
    text-decoration: none;
    border-bottom: 2px solid $black;
    padding: 10px 32px 10px 12px;
    background: $white !important;

    &::after {
      right: 13px;
      margin-top: 2px;
      opacity: 1;
    }
  }

  &:focus {
    text-decoration: none;
    color: $white;
    outline: none;
  }

}


