.page-cart-checkout-review {

	#review-instructions p {
	font-size: 18px;
    letter-spacing: .1px;
    margin-bottom: 20px;
    line-height: 26px;
        font-weight: 500;
    color: #332b28;
	}

	.order-review-table {
		border: 0;
		border-spacing: 0;
		max-width: 600px;
		font-size: 16px;
    	font-weight: 400;

		td {
			border: 0 !important;
		}

		td.products a {
			font-weight: bold;
			color: #085127;
		}
	}

	.order-review-table .pane-title-row {
		border: 0;
		
		td {
			background: #f9f7f3!important;
			color: #000;
			border: 0;
		    padding: 8px 15px;
		}
	}

	.review-button-row {
		background: transparent;

		>td {
			background: transparent;
		}
	}

	.form-actions {
		width: 100%;
		float: left;
		margin-top: 20px;
		margin-bottom: 20px;

		input {
			@extend %cart-button;
		}

		input#edit-submit {
			margin-left: 15px;
		}
	}
}