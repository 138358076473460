.tb-megamenu {
	.mega-dropdown-menu {
		border: 0 !important;
		box-shadow: inset 0px 7px 19px -13px rgba(0,0,0,0.75), 0px 7px 19px -13px rgba(0,0,0,0.75);
	}

	.mega-dropdown-inner {
		background: transparent;
	  width: 100%;
	  max-width: 1219px;
	  margin: 0 auto;
	  float: none;

	  @include MQ__tablet {
	  	border-bottom: 4px solid $jewel;
	  }
	}

	.content {
		@include MQ__tablet--small {
			margin: 0 !important;
		}
	}
}

#mm-fullwidth {
	padding-right: 11px;

	@include MQ__large {
		padding: 0;
	}

	.container {
		width: 100%;
	}

	.area {
		float: left;
	}

	.text {
		width: calc(100% - 912px);
		padding: 48px 10px 0 0;
		margin-right: 39px;

		@include MQ__1200 {
			width: 100%;
			padding: 0;
			margin: 30px 0;
		}

		@include MQ__large {
			display: none;
		}

		h3 {
			color: $zuccini;
	    font-size: 29px;
	    font-weight: 700;
	    margin-bottom: 20px;
	    letter-spacing: -0.4px;
		}

		p {
			font-size: 16px;
			font-weight: 500;
			line-height: 1.4;
  		letter-spacing: 0.1px;
		}
	}

	.cta-wrap {
		width: 873px;
		float: left;
		padding-top: 34px;

		@include MQ__1200 {
			width: 100%;
			padding: 0;
		}

		@include MQ__large {
			width: 100%;
		}

		@include MQ__tablet--small {
			padding: 0;
		}
	}

	.cta {
	  width: 180px;
  	//margin-left: 50px;
  	margin-right: 50px;
  	//float: right;
  	float: left;

  	@include MQ__1200 {
			width: 25%;
			// margin-left: 0;
			// padding-right: 20px;

			margin-right: 0;
			padding-left: 20px;
		}

		@include MQ__large {
			width: 100%;
			padding: 0;
			margin: 0 0 22px 0;
			text-align: center;
		}

  	&:last-child {
  		//margin-left: 0;
  		margin-right: 0;
  	}

		a {
			padding: 0;

			&:hover,
			&:active,
			&:focus {
				background: transparent !important;
				border: 0 !important;
			}
		}

		img {
			width: 100%;
			height: auto;
			margin-bottom: 15px;
			border-radius: 5px;

			@include MQ__tablet--small {
				display: none;
			}
		}

		span {
			display: block;
			color: $jewel;
			font-size: 20px;
			margin-bottom: 7px;
			font-weight: 600;
		}

		p {
			font-size: 16px;
		  background: url(/sites/barjo.co.uk/files/mm-chev.png) no-repeat transparent right 50%;
  		background-size: 6px;
  		letter-spacing: -0.4px;
  		display: inline-block;
  		padding: 0px 15px 0 0;
		}
	}
}