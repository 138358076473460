.page-user-register {

  h2, h3, h4, h5, h6 {
		font-family: "Lato", sans-serif;
	}

	h2,
	.fieldset-legend {
		font-size: 26px;
  	font-weight: 600;

  	@include MQ__large {
  		font-size: 22px;
  	}
	}

	legend {
		margin-bottom: 23px;
	}

	.container {
		padding: 0 25px;
	}

	#content .container {
		@include MQ__tablet--small {
			display: flex;
    	flex-direction: column-reverse;
		}

		> h2 {
			@include MQ__tablet--small {
				display: none;
			}
		}
	}

	#page-header {
		padding: 45px 0 39px;
	}

	.register-form {
		width: calc(100% - 715px);
		width: 421px;
		float: left;
		padding-right: 55px;


		@include MQ__large {
			padding-right: 25px;
		}

		@include MQ__tablet--small {
			width: 100%;
			padding: 0
		}

		.password-strength {
			width: 100%;
		}

		.description {
			font-size: 15px;
  		font-weight: 600;
		}

		.field-default_shipping_item,
		.field-default_billing_item {
			input {
				width: 14px !important;
				float: left;
				margin-right: 13px;
			}

			.form-item {
				font-size: 15px;
  			font-weight: 600;
			}
		}

		.form-actions {
			width: 100%;
			float: left;
			margin: 0 0 20px 0;
		}

	}

	.register-info {
		width: 715px;
		width: calc(100% - 421px);
		float: left;
		padding-left: 43px;
		padding-right: 57px;
    margin-top: 28px;
		border-left: 4px solid $bonjour;

		@include MQ__1200 {
			padding-right: 0;
		}

		@include MQ__large {
	    padding-left: 25px;
		}

		@include MQ__tablet--small {
			width: 100%;
			border-left: 0px;
			border-bottom: 4px solid $bonjour;
			margin: 0 0 20px 0;
    padding: 0 0 40px 0;
		}

		h2 {
			margin-bottom: 13px;
		}

		h3 {
			font-weight: 600;
			font-size: 26px;
			line-height: 29px;

			@include MQ__large {
				font-size: 24px;
    		line-height: 26px;
			}
		}

		img {
			float: right;
	    margin-left: 10px;

	    @include MQ__1200 {
				width: 100%;
				max-width: 110px;
			}

			@include MQ__mobile--small {
				display: none;
			}
		}

		p {
			letter-spacing: 0.2px;
			line-height: 1.7;
			margin-bottom: 33px;

			@include MQ__large {
				line-height: 1.3;
				font-size: 16px;
			}
		}

		a {
			color: $black;

			&:hover {
				text-decoration: none;
			}



			&:last-of-type {
				color: $zuccini;
				text-decoration: underline;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}