#page-section-1, #page-section-10 {
	margin: 0;
	background: $merino;
	padding: 48px 0 50px;
	width: 100%;
	float: left;

	@include MQ__1200 {
		background: $white;
	}

	@include MQ__tablet--small {
		padding: 0 0 40px;
	}

	.container {
		position: relative;
	}

	.region {
		display: flex;
		justify-content: space-around;
		align-items: center; 

		@include MQ__tablet--small {
			flex-wrap: nowrap;
		  overflow-y: auto;
		  flex-direction: row;
		  justify-content: flex-start;
		}
	}

	.int-box {
		display: flex;
		align-items: center;
		flex-direction: row;
		text-align: center;
		max-width: 290px;

		@include MQ__large {
			padding: 0 10px;
		}

		@include MQ__1200 {
			padding: 0 20px 0 0;
		}

		@include MQ__tablet--small {
			width: 100vw;
			max-width: unset;
			padding: 0 30px;
		}

		img {
			height: 50px;
			width: auto;
			margin-bottom: 0px;
		}

		span {
			display: flex;
			flex-direction: column;
			padding-left: 17px;
		    padding-top: 3px;
		    text-align: left;
		}

		h2 {
			font-size: 24px;
			margin-bottom: 5px;
			font-weight: 600;
			letter-spacing: -0.5px;
		}

		p {
			line-height: 18px;
		    font-size: 16px;
		    letter-spacing: 0px;
		    margin: 0;

			@include MQ__1200 {
				font-size: 16px !important;
				line-height: 19px !important;
			}
		}
	}

	#block-block-50 .int-box img {
		height: 65px
	}

	button#right-button,
	button#left-button {
		display: none;
		font-size: 0px !important;
		padding: 10px 20px;
		position: absolute;
		top: 0;
		bottom: 0;

		@include MQ__tablet--small {
			display: block;
		}
	}

	button#right-button {
		background: url(/sites/barjo.co.uk/files/chev-right-grey.png) no-repeat transparent;
    background-position: 50%;
		right: 0;
	}

	button#left-button {
		background: url(/sites/barjo.co.uk/files/chev-left-grey.png) no-repeat transparent;
    background-position: 50%;
		left: 0;
	}
}

#page-section-10 {

	@include MQ__768 {
		padding: 0 0 40px;
	}
}

.not-front #page-section-10 {
	margin-top: 50px;
	padding: 20px 0;

	@include MQ__768 {
		padding: 0 0 40px;
	}
}

#page-header {
	background: url(/sites/barjo.co.uk/files/banner1.jpg) no-repeat transparent;
	background-size: cover;
	background-position: 50%;
  padding: 27px 0 35px;
	margin-bottom: 22px;
  position: relative;

  &:after {
  	content: "";
    background: #00000080;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

	@include MQ__1200 {
		padding: 10px 15px;
	}

	@include MQ__tablet {
		margin-top: 0 !important;
	}

	@include MQ__tablet--small {
    margin-bottom: 20px;
	}


	h1 {
		color: $white;
		position: relative;
		z-index: 1;
	}


	&.catalog {

		.container {
			// display: flex;
			// justify-content: flex-start;
			// align-items: center;
			padding: 0 30px;
			position: relative;
			z-index: 1;
		}

		img {
			float: left;
			margin-right: 18px;

			@include MQ__mobile--small {
				display: none;
			}
		}

		h1 {
			margin-left: 9px;
		  font-size: 35.8px;
		  margin-top: -28px;
		  letter-spacing: -0.8px;
		  color: #fff;

		  span.small {
		  	float: left;
		  	color: #fff;
		  }
		}

		p, span {
		  margin: 10px 0 0 3px;
  		float: left;
		  line-height: 1.1;
		  color: #fff !important; 
		  font-size: 18px;
		    line-height: 1;
		    font-weight: 500;
		    letter-spacing: 0.5px;
		}
	}
}

.node-type-primary-level-page {

	#main-content .content {
		margin: 20px 0;
	}

	// #main-content .container {
	// 	padding: 0 12px;
	// }


	.field-name-body {
		width: calc(100% - 514px);
		float: left;
		padding-right: 39px;
		// margin-bottom: 30px;

		@include MQ__1024 {
			width: calc(100% - 350px);
		}

		@include MQ__tablet {
			width: calc(100% - 250px);
		}

		@include MQ__768 {
			width: 100%;
			padding: 0;
		}

		h2, h3, h4, h5, h6 {
			font-family: $font-family--secondary;
		}

		h2 {
      font-size: 26px;
      line-height: 30px;
	    margin-bottom: 35px;
	    letter-spacing: -0.9px;
	    font-weight: 700;

			span {
				color: $jewel;
				font-weight: 600;
			}
		}

		h3 {
			font-size: 26.5px;
			font-weight: 700;
		}

		ul {
			margin-left: 0 !important;
			margin-top: 20px !important;
			margin-bottom: 50px !important;
			margin-top: 0 !important;
			width: 100%;
			display: block;
			float: left;

			li {
		      	list-style-type: none!important;
			    background: url(/sites/barjo.co.uk/files/tick-green.png) no-repeat #0000;
			    background-position: 3px 60%;
			    padding-left: 35px;
			    letter-spacing: 0;
				font-weight: 500;
			    margin: 12px 10px 0px 0!important;
			    display: block;
			    width: calc(50% - 20px);
			    float: left;

			    @media screen and (max-width: 767px) {
					width: 100%;
					margin: 12px 0 0 0 !important;
				}
			}


		}

		p {
			font-size: 18px;
		    letter-spacing: .1px;
		    padding-left: 5px;
		    margin-bottom: 20px;
		    line-height: 26px;

		    @include MQ__1200 {
		    font-size: 16px;
		  }

		  @include MQ__mobile--large {
		    font-size: 15px;
		    line-height: 1.2;
		  }
		}

		a {
			text-decoration: underline;
		}
	}

	.field-name-field-image,
	.owl-carousel {
		width: 514px;
		float: left;
		border: 0;
		padding: 0;

		@include MQ__1024 {
			width: 350px;
		}

		@include MQ__tablet {
			width: 250px;
		}

		@include MQ__768 {
			display: none;
		}

		img {
			width: 100%;
			float: left;
		}

		.owl-buttons div {
			opacity: 1 !important;
		}
	}

}

.accordion {
		margin-bottom: 30px;
		width: 100%;
		float: left;
		margin-left: -7px;

	.accordion-header {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		float: left;
		border-top: 1px solid $bonjour;
		border-bottom: 3px solid $bonjour;
    padding: 10px 0 9px;
		color: $offblack;
		font-size: 16px;
		font-weight: 500;
		transition: $transition;
	    padding-left: 7px;

		&:first-child {
			border-top: 4px solid $bonjour;
		}

		&:last-child {
			border-bottom: 4px solid $bonjour;
		}

		&:after {
			content: "";
			background: url(/sites/barjo.co.uk/files/chev-right_0.png) no-repeat transparent;
			background-position: 98% 50%;
			width: 20px;
		  height: 20px;
		  position: absolute;
		  right: 20px;
		  transition: $transition--5;
		}

		&:hover {
			cursor: pointer;
			background: #F6F7F9;
		}

		&.active:after {
			transform: rotate(90deg);
		}

		@include MQ__tablet--small {
			border-bottom: 0;
			pointer-events: none;
  		cursor: default;

			&:after {
				transform: rotate(90deg);
			}
		}
	}

	.accordion-content {
		display: none;
		border-bottom: 1px solid #DDE0E7;
		padding: 1.5rem;
		color: #4a5666;
		width: 100%;
		float: left;
		line-height: 24px;
		font-weight: 400;
		font-size: 16px;

		@include MQ__tablet--small {
			padding-top: 0;
			display: block !important;
		}
	}
}

.page-node-3461 .field-name-body {
	width: 100%;
	padding: 0;
}

.node-type-primary-level-page.page-node-3477S .field-name-body {
	width: 100%;
	float: left;
} 

.cage-layout {
	width: 100%;
	float: left;
	padding-bottom: 26px;
	padding-left: 2px;
	margin-bottom: 21px;
	border-bottom: 3px solid #f1f1f1;

	&:last-of-type {
		border-bottom: 0;
	}

	.opening {
		width: 100%;
		margin-bottom: 6px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	img {
		width: 28px;
		height: auto;
		margin-right: 10px;
	}

	.blurb {
    padding-right: 50px;

    @include MQ__768 {
    	padding: 0;
    }

		p {
			margin: 0;
			line-height: 25px;
	    letter-spacing: 0px;
		}

		a {
			text-decoration: underline;
			color: $jewel;
		}

		h3 {
			font-size: 23.5px;
			font-weight: 500;
		}
	}
}

.page-node-1488 {
	.cage-layout .blurb p {
		padding-left: 0px;  
	}

	&.node-type-primary-level-page .field-name-body h3 {
		font-size: 22px;
	} 
}

.page-node-3472 {
	&.node-type-primary-level-page .field-name-body p {
		margin-bottom: 50px !important
	}
}