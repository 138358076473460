.page-node-3461,
.page-node-3472,
.page-node-3477,
.page-node-3481  {
	.view-product-tabs-displays .views-row, .view-uc-catalog .views-row {
		width: 277px;
    	margin: 0 10px 30px;
		float: left;
		text-align: center;
		// margin-bottom: 20px;
		// margin-right: 34px;
		border: 1px solid $bonjour;

		@include MQ__768 {
			width: calc(50% - 30px);
			margin: 0 15px 35px;
		}

		@include MQ__mobile--large {
			width: calc(50% - 10px);
			margin: 0 5px 0;

		}

		@include MQ__mobile--small {
		  width: 100%;
  		margin: 0;
		}
	}

	.view-product-tabs-displays .buttons {
		a, form {
			width: 50%;
		}

	}
}

.page-node-3461 .view-product-tabs-displays .views-row {
	width: 277px;
}