// ==========================================================================
// Global Config
// ==========================================================================

// Font Stacks

$font-family--primary: 'Montserrat', sans-serif;
$font-family--secondary: 'Lato', sans-serif;

// font-family: 'Lato', sans-serif;
// font-family: 'Montserrat', sans-serif;

$maxwidth: 1260px;
$padding: 0 30px;

// Font Weights

$fw--light: 200;
$fw--book: 300;
$fw--medium: 400;
$fw--bold: 700;

$zuccini: #085127;
$elephant: #153a53;
$poppy: #b8262d;
$jewel: #14723c;
$birch: #383625;
$spring: #F9F7F3;
$fuel: #e8bb20;

$zuccini--trans: rgba(8, 81, 39, 0.8);
$elephant--trans: rgba(21, 58, 83, 0.8);
$poppy--trans: rgba(184, 38, 45, 0.8);

$merino: #f9f7f3;
$offblack: #332b28;
$grey : #534d4a;
$bonjour: #f2f0f1;

// Descriptive Base Colors

$white: #fff;
$black: #000;
$black--trans: rgba(0, 0, 0, 0.6);
$black--trans-light: rgba(0, 0, 0, 0.4);

// Color Usage

$color-primary: $white;
$color-secondary: $black;


// Color Palette Modifiers

$color-palettes: (white: (dark: darken($white, 8%), x-dark: darken($white, 16%), xx-dark: darken($white, 45%), base: $white));

// Border Radius

$br--default: 10px;
$br--heavy: 30px;
$br--round: 50%;

// Text

$base__font-size: 18px;
$base__line-height: normal;
$letter-space: normal;

// Path

$path--image: "/sites/threeT/themes/threeT/images/";

// Mobile Navigation

$navigation-width: 130px;
$nav-menu-animation-time: 0.3s;

// Breakpoints

$brkpoint__mobile--small: 374px;
$brkpoint__mobile--medium: 410px;
$brkpoint__mobile--large: 500px;
$brkpoint__tablet--small: 767px;
$brkpoint__768: 768px;
$brkpoint__tablet: 979px;
$brkpoint__large: 1023px;
$brkpoint__1024: 1024px;
$brkpoint__extra-large: 1170px;
$brkpoint__1200: 1200px;
$brkpoint__1439: 1439px;
$brkpoint__1600: 1600px;
$brkpoint__1900: 1900px;

// Max-width Viewports

$sip-max-width: 1275px;
$percentage-width: 90%;

$transition: all 0.25s ease;
$transition--5: all 0.5s ease;
$transition--75: all 0.75s ease;