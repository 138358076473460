header {

  #topUserMenu {
    border-radius: 0;
    margin: 0;

    @include MQ__tablet--small {
      right: 0px !important;
    }

    @include MQ__mobile--large {
      right: -90px !important;
    }

    a {
      display: block;
    }

    li.greeting a {
      @extend %cart-button;
      padding: 12px;

      margin-bottom: 5px;
    }

    li.logout a {
     @extend %button-grey;

     background: #F9F7F3 !important;
      text-transform: none;
      padding: 12px;
    }



    #accountUtils {

      a.new-login {
        @extend %cart-button;

        padding: 12px;
        color: $offblack;
      }

      a.new-account {
        @extend %button-grey;

        text-transform: none;
        padding: 12px;
      }
    }
  }


  .dropdown-menu {
		padding: 30px;
    border-radius: 0;
    box-shadow: 0px 0px 10px #d4d4d4;
    border: 0;

    @include MQ__tablet {
      right: -150px;
    }

    @include MQ__tablet--small {
      right: 0px;
      min-width: 460px;
      padding: 15px;
      top: 50px;
    }

    @include MQ__mobile--large {
      min-width: unset;
      right: -50px;
    }

    @include MQ__mobile--small {
      width: 250px;
    }

	}

  .topCart {
    margin-bottom: 20px;
  }

  .col-sm-8  {
    padding-left: 0px !important;

    p {
      @include MQ__mobile--large {
        font-size: 15px;
      }
    }

    a {
      text-transform: none;
      color: $offblack;
      display: block;
      padding: 0 !important;
    }
  }

  .col-sm-4 p {
    color: #000 !important;

    @include MQ__mobile--large {
      font-size: 15px;
    }
  }

  .total-new {
    padding-right: 0 !important;

    span {
      font-size: 20px;
      font-weight: 400;
    }
  }

	a.edit-basket {
  	@extend %button-grey;

    color: $offblack;
  	padding: 7px 12px;
  	text-transform: none;
  	float: right;
    font-weight: 500;

    &:hover {
      color: $offblack;
    }
  }

	a.go-checkout {
  	@extend %cart-button;

  	color: $offblack;
    text-transform: none;
  	margin-left: 10px;
  	padding: 7px 12px;
  	float: right;
    font-weight: 500;
  }
}