.page-user-register {
	div#content {
		width: 100%;
		float: left;
	}

	.form-item {
		width: 100% !important;
		float: left;
		margin-bottom: 20px;
	}

	fieldset {
		padding: 0
	}

	// #edit-account .form-item label,
	// #edit-uc-addresses > legend,
	// fieldset#edit-mimemail legend,
	// fieldset#edit-contact legend {
	// 	background: $zuccini;
	// 	color: $white;
	// }

	.form-actions {
		input {
			@extend %cart-button;
		}
	}
}