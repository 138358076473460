// ==========================================================================
// Mixins
// ==========================================================================

// BEM selectors

@mixin e($element) {
  &__#{$element} {
    @content;
  }
}

@mixin m($modifier) {
  &--#{$modifier} {
    @content;
  }
}

// Media Queries

@mixin MQ__1900 {
  @media screen and (max-width: #{$brkpoint__1900}) {
    @content;
  }
}

@mixin MQ__1600 {
  @media screen and (max-width: #{$brkpoint__1600}) {
    @content;
  }
}

@mixin MQ__1439 {
  @media screen and (max-width: #{$brkpoint__1439}) {
    @content;
  }
}

@mixin MQ__1200 {
  @media screen and (max-width: #{$brkpoint__1200}) {
    @content;
  }
}

@mixin MQ__extra-large {
  @media screen and (max-width: #{$brkpoint__extra-large}) {
    @content;
  }
}

@mixin MQ__1024 {
  @media screen and (max-width: #{$brkpoint__1024}) {
    @content;
  }
}

@mixin MQ__large {
  @media screen and (max-width: #{$brkpoint__large}) {
    @content;
  }
}

@mixin MQ__tablet {
  @media screen and (max-width: #{$brkpoint__tablet}) {
    @content;
  }
}

@mixin MQ__768 {
  @media screen and (max-width: #{$brkpoint__768}) {
    @content;
  }
}

@mixin MQ__tablet--small {
  @media screen and (max-width: #{$brkpoint__tablet--small}) {
    @content;
  }
}

@mixin MQ__mobile--large {
  @media screen and (max-width: #{$brkpoint__mobile--large}) {
    @content;
  }
}

@mixin MQ__mobile--medium {
  @media screen and (max-width: #{$brkpoint__mobile--medium}) {
    @content;
  }
}

@mixin MQ__mobile--small {
  @media screen and (max-width: #{$brkpoint__mobile--small}) {
    @content;
  }
}

@mixin target-edge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@mixin target-IE {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

// Homepage section color
@mixin hsc($hscolor) {
  border-right-color: $hscolor;
  border-bottom-color: $hscolor;
}

// Mixin for case study sectors if color is selected
@mixin caseStudySector($sector, $color) {
  .sip-title.industrySector {
    @include m($sector) {
      .coloured-background {
        background: $color;
      }
    }
  }

  .sip-title.industrySector {
    @include m($sector) {
      .coloured-background--skew {
        border-top-color: $color;
        border-left-color: $color;
      }
    }
  }
}

@mixin caseStudyTestimonial($sector, $color) {
  .industrySector {
    @include m($sector) {
      border-bottom-color: $color;
      border-right-color: $color;
    }
  }
}

@mixin industrySectorTeamView($sector, $color) {
  &.industrySector {
    @include m($sector) {
      a {
        color: $color;
      }

      svg {
        fill: $color;
      }
    }
  }
}

@mixin industrySectorColor($sector, $color) {
  &.industrySector {
    @include m($sector) {
      color: $color;
    }
  }
}

@mixin industrySectorTeamColor($sector, $color) {
  &.industrySector {
    @include m($sector) {
      h3, h4 {
        color: $color;
      }
    }
  }
}

@mixin industrySectorSlider($sector, $color) {
  &.industrySector {
    @include m($sector) {
      border-bottom-color: $color;
      border-right-color: $color;
    }
  }
}

@mixin industrySectorSliderBackground($sector, $color) {
  &.industrySector {
    @include m($sector) {
      background: $color;
    }
  }
}

@mixin caseStudiesViewBox($sector, $color, $colorTrans) {
  .homepage-sections-box__inner {
    &.industrySector {
      @include m($sector) {
        background: $colorTrans;
      }
    }
  }

  .homepage-sections-box__border {
    &.industrySector {
      @include m($sector) {
        border-right-color: $color;
        border-bottom-color: $color;
      }
    }
  }
}

@mixin newsGrid($sector, $color) {
  &.industrySector {
    @include m($sector) {
      border-right-color: $color;
      border-bottom-color: $color;
    }
  }
}