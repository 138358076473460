.view-product-filter {

	.view-filters {
		width: 100%;
	  	float: left;
	  	margin-bottom: 14px;
		color: $white;

		.titles {
			width: 100%;
			float: left;
			margin-bottom: 21px;
			display: flex;
			align-items: center;

			p {
				margin-bottom: 0;

				@include MQ__1200 {
					color: $black;
				}
			}
		}

		h1 {
			float: left;
			margin-right: 21px;
			line-height: 1;
			letter-spacing: -0.5px;
 			font-size: 35px;
 			font-weight: 500;
		}

		p {
			color: $white;
      margin: 7px 0 20px 0px;
	    font-size: 18px;
	    font-weight: 400;
	    letter-spacing: 0px;

			@include MQ__tablet--small {
				color: $black;
			}
		}
	}

	
	.filter-prompt {
		width: 100%;
		float: left;
		display: block;
		background: $white;
		height: 0;
		opacity: 0;
		transition: $transition;
		z-index: -1;

		@include MQ__768 {
			display: none;
		}

		p {
			color: $zuccini !important;
			font-size: 16px;
			margin: 0;
		}

		&.show {
			opacity: 1;
		    height: 36px;
		    padding: 10px;
		    margin-bottom: 10px;
		}
	}

	.view-footer {
		width: 100%;
		float: left;
		display: flex;
  		

		p {
			float: left;
			margin: 0 20px 0 0;
			color: $white;
			font-size: 18px;
			font-weight: 400;
			letter-spacing: 0.6px;

			@include MQ__1200 {
				color: $black;
			}

			@include MQ__768 {
		  		display: none;
		  	}
		}

		.makes {
			display: flex;
			float: left;

			@include MQ__768 {
		  		display: none;
		  	}

			img {
				width: auto;
				height: 35px;
				margin-right: 10px;
			}

			a.last {
				line-height: 1.9;
			    color: #e8bb20;
			    font-weight: 500;
			}
		}

		img.tp {
			margin: 0 0 0 auto;

			@include MQ__large {
		 		display: none;
		 	}
		}
		
	}

}

.view-product-filter form {
	width: auto;
	float: left;
	margin-right: 10px;

	@include MQ__1024 {
		width: 75%;
	}

	@include MQ__768 {
		width: 100%;
		margin: 0;
	}

	.views-exposed-widgets {
    	margin-bottom: 0.4em;
	}

	
	.views-exposed-widget {
		width: 100%;
		padding: 0;
	}

	.form-item {
		display: flex;
		align-items: center;
		justify-content: flex-start;


		@include MQ__768 {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

	select {
		background: url(/sites/barjo.co.uk/files/arrow-down.png) no-repeat #fff;
		background-position: 92% 55%;
		width: 200px;
		border: 0;
	  margin: 0;
    width: 227px;
    color: #333;
		padding: 12px 13px 12px;
		font-size: 16px !important;
    font-weight: 400;
    -webkit-appearance: none !important;

    @include MQ__1024 {
    	background-position: 97% 55%;
    	width: 100%;
    }

	  @include MQ__768 {
	  	width: calc(100% - 53px);
	  	float: left;
	  	margin-bottom: 10px;
	  }

		&#edit-taxonomy-catalog-tid-select-1 {
			order: 2;

			option:nth-child(4) { //Hide Barjo range option
				display: none;
			}
		}

		&#edit-taxonomy-catalog-tid-select-2 {
			order: 4;
		}

		&#edit-taxonomy-catalog-tid-select-3 {
			order: 6;
		}
	}

	.filter-num {
		background: $fuel;
		color: $black;
  	font-weight: 700;
  	margin: 0 0 0 9px;
  	font-family: $font-family--primary;
  	opacity: 0;
  	padding: 0;
  	transition: $transition;
  	font-size: 20px;

  	@include MQ__768 {
  		width: 53px;
  		margin: 0 0 10px 0 !important;
  	}

		&.one {
			order: 1;
			margin: 0;
		}

		&.two {
			order: 3;
		}

		&.three {
			order: 5;
		}

		&.active {
			opacity: 1;
	    padding: 10px 18px 9px 21px;
	    
		}
	}
	
}

a#barjo-show-products {
	@extend %cart-button-lg;

	color: $black;
	width: 180px;
	padding: 11px 15px;
	float: left;
	border: 0;
	font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: 0px;

  @include MQ__1024 {
  	width: calc(25% - 30px);
  }

  @include MQ__768 {
  	width: unset;
  }

  &:hover {
  	text-decoration: none;
  }

	// &.disabled {
	// 	pointer-events: none;
	// 	cursor: default;
	// }
}