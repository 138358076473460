.view-uc-catalog-terms {
	width: 100%;
	float: left;
	margin-bottom: 45px;
	
	.views-row {
		width: calc(20% - 20px);
		float: left;
		margin: 0 10px 10px;

		@include MQ__tablet--small {
			width: calc(33% - 20px);
    		margin: 0 10px 15px;
		}

		@include MQ__mobile--large {
			width: calc(50% - 10px);
    		margin: 0 5px 7.5px;
		}
	}

	.views-field.views-field-name {
		padding: 0;
		display: flex;
		text-align: center;
		justify-content: center;
		background: $bonjour;

		&:hover {
			background: #248e51 !important;
			color: #FFF !important;

			span.field-content a {
				transition: none !important;
				color: #fff !important;
			}
		}
	}

	span.field-content {
	  width: 100%;
	  text-align: center;
	  font-size: 17px;
	  font-weight: 400;

	  a {
	  	width: 100%;
	  	height: auto;
	  	display: block;
	  	padding: 50px 0;
	  	color: $black !important;
	  	transition: none !important;
	  }
	}
}