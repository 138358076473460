.page-cart {


	#uc-cart-view-form {
		width: 100%;
		float: left;
		border-radius: 0;
		background: $white;
		box-shadow: none;
  	margin-bottom: 0;
  	padding: 0;
  	font-weight: 500;
  	font-size: 16px;

		img {
			display: none;
		}

		input[type="submit"] {
			@extend %cart-button;
		}

		table {
			border: 0;
		  border-spacing: 0;
		}

		td.desc a {
		  pointer-events: none;
		  cursor: default;
		  text-decoration: none;
		  color: $zuccini;
		  font-weight: 600;
		}

		.form-actions {
			width: 100%;
			float: left;
			margin-top: 20px;
			margin-bottom: 20px;
			padding: 0;

			a {
				@extend %button-grey;
			}

			input#edit-checkout--2 {
				margin-left: 10px;
			}
		}

		table > thead > tr > th, 
		table > tbody > tr > th, 
		table > tfoot > tr > th, 
		table > thead > tr > td, 
		table > tbody > tr > td, 
		table > tfoot > tr > td {
			vertical-align: middle;  
		}
	}
}