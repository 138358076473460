#ccc {
	svg {
		fill: $zuccini !important;
	}

	p {
	    font-size: 15px !important;
	    line-height: 1.1 !important;
	    font-weight: 500 !important;
	    color: #332b28 !important;
	}

	.ccc-button-solid {
		background: $zuccini !important;
		border-color: $zuccini !important;
	}

	.checkbox-toggle {
	    background-color: $zuccini !important;
	    border-color: $zuccini !important;
	}


	.checkbox-toggle-toggle {
		background: #237d49 !important;
	}

	.checkbox-toggle-off, .checkbox-toggle-on {
		color: $white !important;
	}
}