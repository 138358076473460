form[id^=uc-product-add-to-cart-form-] {

	.attributes {
		width: calc(100% - 200px);
		float: left;

		@include MQ__tablet {
			width: 100%;
			margin-bottom: 0px;
		}

		select {
		  margin: 0;
		  padding: 7px 10px;
		  font-size: 18px !important;
		}

		label {
			display: none;
		}
	}

	.form-item.form-type-uc-quantity {
		display: none;
	}

	div#edit-actions {
		width: 150px;
		float: left;
		margin-left: 15px;

		@include MQ__tablet {
			width: 100%;
			margin: 0;
		}

	}

	input.node-add-to-cart {
		@extend %cart-button-lg;
		width: 100%;
		display: inline-block;
	}
}