.page-user {

	.confirm-parent, .password-parent {
	    width: 100%;
	    max-width: 500px;
	}

	.default-billing-address, .default-shipping-address {
		@include MQ__tablet--small {
			width: 100%;
			margin: 0 0 50px !important;
		}
	}

	ul.tabs {
        margin: 0 auto 30px;
	    width: 100%;
	    float: left;
	    background: transparent !important;

	    li {
	    	background: transparent !important;
	    	padding: 0 !important;
	    	float: left;
    		margin: 0 10px 0 0;
	    }

	    a {
	    	@extend %button-stnd;

	    	@include MQ__mobile--large {
			    font-size: 15px;
			    line-height: 1.2;
			  }
	    }
	}

	.address-book .default-addresses {
		width: 100%;
	}

	input#edit-submit {
		@extend %cart-button;
	}

}