.front {

	.mobilebanner {
		display: none;

		img {
			width: 100%;
			height: auto;
		}

		@include MQ__tablet--small {
			display: block;
		}
	}

	.wrapper {
		@include MQ__tablet--small {
		  display: flex;
		  flex-direction: column;
		  flex-wrap: nowrap;
		  justify-content: center;

		  #page-section-1 {
		  	order: 3;
		  }

		  #page-section-3 {
		  	order: 1;
		  }

		  #page-section-4 {
		  	order: 2;
		  }

		  footer {
	  		order: 4;
		  }

		}
	}

	#page-section-2 {
		background: $zuccini--trans;
		margin: 0;
		position: absolute;
	  bottom: 0;
	  z-index: 1;

	  @include MQ__1200 {
	  	display: none;
  	}

	  .container {
	  	max-width: 1269px;
	  }

		.region {
			display: flex;
			align-items: stretch;
			justify-content: flex-start;
		}

		#block-block-47 {
			background: $jewel;

			p {
				padding: 20px 16px 20px 22px;
		    margin: 0;
		    font-size: 19px;
				font-family: $font-family--primary;
				font-weight: 500;

			}
		}

		p {
			color: $white;
			max-width: unset;
			margin: 0;
	    padding: 17px 22px;
  		letter-spacing: 0.4px;
  		font-weight: 300;
  		font-size: 20px;
			
		}
	}



	#page-section-3 {
		padding: 0;
		margin: 0;
		background: #f9f7f3 !important;

		.container {
			max-width: 90%;
			padding: 0;
		}

		.block {
			width: 33.333%;
			float: left;
		}

		.feature-box {
			position: relative;
			border-right: 1px solid #f9f7f3;
			overflow: hidden;
			border-right: 4px solid #f9f7f3;   

			@include MQ__tablet--small {
				border: 0;
			}

			.title {
				position: absolute;
				left: 0;
				text-align: center;
				width: 100%;
				z-index: 1;
				background: rgba(42, 35, 31, 0.9);
				padding: 22px 22px 22px 38px; 
				text-align: left;
				bottom: 0;   

				@include MQ__1024 {
					padding: 14px 10px;
				}

				@include MQ__tablet--small {
					height: 100%;
				  display: flex;
				  align-items: center;
				  justify-content: center;
				}

				h2 {
					color: $white;
					font-size: 35px;
					background: url(/sites/barjo.co.uk/files/arrow-cta.png) no-repeat transparent;
					background-position: right 65%;
					display: inline;
					padding-right: 33px;
					font-weight: 600;

					@include MQ__1200 {
						font-size: 28px;
						background-size: 13px;
						padding-right: 23px;
					}

					@include MQ__1024 {
						font-size: 24px;
 					}

 					@include MQ__tablet--small {
 						text-align: center;
 					}

 					@include MQ__mobile--small {
 						font-size: 18px;
 					}
				}

				p {
					color: $white;
					margin: 7px 0px 0px;
					letter-spacing: 0px;

					@include MQ__tablet--small {
						display: none;
					}
				}
			}

			img {
				width: 100%;
				height: auto;
				transition: $transition--5;

				@include MQ__tablet--small {
					width: auto;
					height: 300px;
				}

				@include MQ__mobile--large {
					height: 200px;
				}
			}

			span {
		    position: absolute;
		    bottom: 26px;
		    right: 30px;
		    display: inline-block;
		    padding: 14px 24px;
		    font-weight: 500;
		    color: $white;
		    background: #085127;
		    z-index: 1;
		    letter-spacing: 0.8px;

				@include MQ__tablet--small {
					display: none !important;
				}

				&.green {
					background: $zuccini;
				}

				&.blue {
					background: $elephant;
				}

				&.red {
					background: $poppy;
				}
			}

			&:hover {
				img {
					transform: scale(1.05);
				}
			}
		}
	}

	#page-section-4 {
		padding: 45px 0 5px;

		.container {
			max-width: 1277px;
		}

		@include MQ__mobile--large {
			padding: 20px 0;
		}

		.container {
			@include MQ__mobile--large {
				padding: 0;
			}
		}

		.region {
		  display: flex;
		  align-items: stretch;
		  justify-content: flex-start;

		  @include MQ__large {
		  	flex-wrap: wrap;
		  }
		}

		#block-block-56 {
			width: calc(100% - 409px);
			float: left;
			padding-right: 71px;
			padding-left: 8px;

			@include MQ__large {
				width: 100%;
			  padding: 0 35px 40px 35px;
			  border: 0;
			}

			@include MQ__tablet--small {
				padding: 15px;
			}

			@media screen and (max-width: 768px) {
				padding: 0 10px 40px !important;
			}

			img {
				position: absolute;
				top: 0;
				right: 17.7%;

				@include MQ__1439 {
					right: 0;
				}

				@include MQ__1200 {
					right: 0px !important;
			    top: unset;
			    width: 120px;
			    bottom: 0;
				}

				@include MQ__large {
					width: 120px;
					top: 0;
					right: 0;
				}

				@include MQ__tablet--small {
					display: none;
				}
			}
		}

		h2 {
			color: $offblack;
			font-size: 60px;
			display: block;
		  width: 100%;
		  max-width: 400px;
		  font-weight: 600;
		  margin: 96px 0 33px;
		  letter-spacing: -1.4px;

		  @include MQ__large {
		  	margin: 20px 0 40px;
		  	margin-right: 130px;
		  }

		  @include MQ__tablet--small {
		  	font-size: 42px;
		  	max-width: unset;
		  	width: auto;
		  	margin: 0 0px 20px 0;
		  }
		}

		p {
			font-size: 18px;
			letter-spacing: 0.1px;
			padding-left: 5px;
			margin-bottom: 20px;
			line-height: 26px;

			@include MQ__1200 {
				font-size: 16px;
				line-height: 22px;
				margin-bottom: 20px;
				margin-right: 0px;
			}

			@media screen and (max-width: 768px) {
				width: 100%
			}

			&.feat {
				font-size: 23px !important;
		    font-weight: 600;
		    margin-left: -4px;
		    margin-top: 14px;
		    width: 100%;
		    float: left;
		    letter-spacing: 0.9px !important;
			}
		}

		.info-left-head {
			width: 100%;
			float: left;
			position: relative;
		}

		#block-block-57 {
			width: 400px;
			float: left;
			border-left: 4px solid #f2f0f1;
			padding-top: 10px;
    	margin-top: 42px;
    	margin-bottom: 18px;
			display: flex;
  		align-items: flex-start;

  		@include MQ__large {
  			width: 100%;
  			flex-wrap: wrap;
  			border-left: 0;
  		}

  		@include MQ__tablet--small {
  			display: none;
  		}

  		.content {
  			@include MQ__large {
  				display: flex;
  				align-items: stretch;
  			}
  		}


  		p {
  			font-size: 18px;
  			margin-bottom: 35px;
  			line-height: 24px;

  			@include MQ__1200 {
					font-size: 16px;
					line-height: 22px;
				}
  		}

  		img {
  			position: absolute;
  			right: 29px;
  			bottom: 21px;

  			@include MQ__large {
  				right: 40px;
  				width: 140px;
  				bottom: 15px;
  			}

  			@include MQ__tablet--small {
  				display: none;
  			}
  		}
		}

		.top, .bottom {
			padding: 30px 40px 45px 40px;
			position: relative;

			@include MQ__large {
				width: 50%;
				float: left;
				border-bottom: 4px solid $merino;
			}

			@include MQ__tablet--small {
				padding: 15px 20px;
			}

			@include MQ__mobile--large {
				width: 100%;
			}
		}

		.top {
			border-bottom: 4px solid $merino;
			padding-top: 0;

			@include MQ__large {
				border-right: 4px solid $merino;
			}

			@include MQ__mobile--large {
				border-right: 0;
			}
		}

		.bottom {
			padding-bottom: 30px;
		}

		h3 {
			font-size: 35px;
			margin-bottom: 16px;
			font-weight: 600;
		}
	}

	#page-section-5 {
		background: $merino;
		padding: 0 0 88px 0;

		@media screen and (max-width: 768px) {
			margin-top: 0;
		    order: 2;
		}

		.container {
			max-width: 1301px;
		}
	}
}