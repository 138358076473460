.page-user {

  #content {
    width: 100%;
    float: left;
  }

  section#page-header {
    margin-bottom: 17px;
  }

  h2 {
    font-size: 22.5px;
    font-weight: 500;
  }


	form#user-login,
  form#user-pass {
		background: transparent;
    max-width: 367px;
    padding: 0;
    margin-bottom: 30px;
    margin-top: 11px;

  	.clearfix a {
  		color: $zuccini;
  		text-decoration: underline;
  		font-weight: bold;
      font-size: 14px;
  	}

		input[type="submit"] {
			@extend %cart-button-lg-green;

			margin-top: 20px;
		}
	}	
}