form.webform-client-form,
form#user-login,
form#user-pass,
form#user-register-form {

	width: 100%;
	float: left;	

	> div {
    width: 100%;
		float: left;
	}

	.form-item {
		width: 100%;
		float: left;
		margin-bottom: 11px;

		&.webform-component-markup {
			margin: 0;
		}

		&.webform-component-checkboxes {
			margin-bottom: 2px;

			label {
				width: calc(100% - 30px);
				font-size: 13.8px !important;
			}

			.form-checkboxes {
				  width: 15px;
				  float: left;
				  margin-right: 15px;
			}

			.form-item label {
				display: none;
			}
		}

		.webform-component--marketing-info-tick {
				margin-bottom: 3px;
			}
	}

	h2 {
		margin-bottom: 27px;
		font-size: 26.5px;
    font-weight: 600;
    font-family: "Lato", sans-serif;
	}

	label {
		font-size: 15px !important;
		margin-bottom: 7px;
		font-weight: bold !important;
		line-height: unset !important;
	}

	input,
	textarea,
	select {
		width: 100%;
		float: left;
		border: 0;
		background: $bonjour;
		padding: 13px;
		font-size: 15px !important;
		box-shadow: none;
	}

	select {
		background: url(/sites/barjo.co.uk/files/arrow-down.png) no-repeat $bonjour;
    background-position: 92% 55%;
    -webkit-appearance: none !important;
	}

	textarea {
  	height: 148px;
  }

	.form-actions {
		margin-top: 20px;
		
		input {
			@extend %cart-button-lg-green;

			padding: 13px 21px;
			width: auto;
		}
	}
}

div.form-item div.password-suggestions {
	width: 100%;
	float: left;
}

.address-pane-table {
	padding: 0;
}

fieldset {
	width: 100%;
	float: left;
	border: 0;
}

.address-pane-table table td {
  padding: 0 !important;
  display: block;
  border: 0 !important;
}

#edit-uc-addresses .address-pane-table .field-label {
	font-size: 15px !important;
  margin-bottom: 0px;
  font-weight: bold !important;
  line-height: unset !important;
  padding-left: 0;

}

.messages {
    width: 100%;
    float: left;
    margin: 0;
}

div.error, table tr.error {
  border-color: #fff;
  color: #333333;
  background-color: #f2f0f1;

}

.webform-confirmation p {
	font-size: 18px;
    letter-spacing: .1px;
    padding-left: 5px;
    margin-bottom: 20px;
    line-height: 26px;
        font-weight: 500;
    color: #332b28;
    margin: 0 0 20px;
}

div.messages ul {
    margin: 0px !important;
} 