form#uc-cart-checkout-form {
	width: 100%;
	float: left;
	font-size: 16px;
    font-weight: 400;

    fieldset#uc_ct-pane label.option, 
    form#uc-cart-checkout-form .form-type-checkbox label, 
    form#uc-cart-checkout-form .form-type-radio label {
    	font-weight: 400;  
		font-size: 16px !important;
    }

	.fixed-container {

		span.fieldset-legend {
		    background: #14723c !important;
    		color: #ffffff!important;
		}

		.fieldset-wrapper {
			padding: 0;
		}

		table {
			border-spacing: 0;
		}

		tr.subtotal {
			display: none;
		}

		thead th {
			font-size: 14px;
		}

		td.products a {
			color: $zuccini;
  		font-weight: 600;
		}

		ul.product-description {
		  display: none;
		}
	}

	fieldset {
		border: 0;
		outline: 0;
		padding: 0;

		legend {
			background: $zuccini;

			span, a {
				color: $white;
			    font-size: 20px;
				font-weight: 500;
			}
		}
	}

	input.form-submit {
		@extend %cart-button;

	    background: #14723c !important;
    	color: #ffffff!important;
    	font-weight: 500!important;
	}

	.address-pane-table {
		.field-label {
			text-align: left;
		}
	}

	.form-actions input#edit-cancel {
		display: none;
	}
}

#line-items-div table,
input#edit-panes-quotes-quote-button--5 {
	display: none;
}

#paypal-includes {
    font-weight: bold;
    display: flex;
    flex-direction: row;
}

.uc-cart-checkout-form {
	legend {
		background: #f9f7f3 !important;

		a, span {
			color: #000 !important;    
			font-size: 18px;
		}
	}

	a.fieldset-title {
		color: #000 !important;  
	}

	fieldset div a {
		text-decoration: underline;
	}
}

.page-cart-checkout-complete .region-content .content p {
    font-size: 18px;
    letter-spacing: .1px;
    padding-left: 5px;
    margin-bottom: 20px;
    line-height: 26px;
}

.page-cart-checkout-complete .region-content .content p a {
	text-decoration: underline;
}