.tb-megamenu { // Main container 
  background-color: transparent !important;
  width: 100%;
  max-width: unset; // allows for full width dropdown
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;

  .nav-collapse { // Main UL container
    width: 100%;
    max-width: 1175px;
    margin: 0 auto;
    float: right;

    @include MQ__large {
      background-color: $white;
      top: 50px;
    }
  }

  ul.tb-megamenu-nav { // Top Level Menu Items
    float: right;
    display: flex;
    align-items: center;

    @include MQ__large {
      flex-direction: column;
    }

  	li {
      @include MQ__large {
        width: 100%;
      }

      &.animating, &.open {
        background: transparent !important;

        a {
          background: transparent !important;
        }
      }
      
  		a {
  			text-transform: none;
  			transition: none;
        padding: 24px 15px;
        border-bottom: 4px solid transparent;
        letter-spacing: -0.4px;

        @include MQ__large {
          border: 0 !important;
          font-size: 19px !important;
          text-transform: unset !important;
        }

  			&:focus,
  			&:hover {
  				background-color: $merino !important;
  				//color: $white !important;
          border-bottom: 4px solid $fuel !important;
  			}
  		}

      &.active >,
      &.dropdown.active > {
        a,
        a:hover,
        a:focus {
          background-color: $merino !important;
          color: #464646 !important;
          border-bottom: 4px solid $fuel !important;
        }
      }

      &.contact {
        margin-left: 15px;
        
        @include MQ__large {
          margin: 0;
        }

        a {
          @extend %button-arrow;
          width: 130px;
          padding: 10px 19px;

          @include MQ__large {
            width: 100%;
          }

          &:focus,
          &:hover {
            background: $white;
            border: 2px solid $black !important;
          }
    
        }
      }

      &.dropdown {
        &.active > .dropdown-toggle {
          background-color: $jewel;
        }
      }
  	}
  }

  .dropdown-menu {
    background: $white !important;
  }

  .btn-navbar { //Mobile Menu Btton 
    margin-top: 12px;

    @include MQ__tablet {
      margin-top: 0;
    }

    &:before {
      content: "";
    }

    i {
      background: url(/sites/barjo.co.uk/files/mobile-nav-burger.png) no-repeat transparent;
      font-size: 0px;
      width: 26px;
      height: 20px;
      display: block;
      background-position: center;
      background-size: contain;
    }

    i:before {
      content: "";
    }

    i:after {
      display: none;
      content: '';
    }
  }
}