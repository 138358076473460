.jumbotron {
	padding: 0;
	margin: 121px auto 0;
	height: 600px;
	overflow: hidden;

 	@include MQ__1200 {
		height: 520px;
	}		

	@include MQ__tablet {
		margin-top: 0;
	}

	@include MQ__768 {
		height: auto;
	}

	// @include MQ__mobile--large {
	// 	height: 600px;
	// }

	.view-homepage-slider {
		@include MQ__tablet--small {
			display: none;
		}
	}

	nav.slick__arrow {
		position: absolute;
		width: 100%;
  	text-align: center;
	  top: 45%;

	  @include MQ__1024 {
	  	display: none;
	  }

  	.slick-prev {
  		float: left;
  		background: url(/sites/barjo.co.uk/files/chev-left.png) no-repeat transparent;
  		font-size: 0px !important;
		  padding: 10px;
		  margin-left: 15px;
  	}

  	.slick-next{
  		float: right;
  		background: url(/sites/barjo.co.uk/files/chev-right.png) no-repeat transparent;
  		font-size: 0px !important;
		  padding: 10px;
		  margin-right: 15px;
  	}
	}

	.slick-slide img {
	  height: 100%;
	  margin: 0;
	  width: auto;
	  max-width: 1920px;

	  @include MQ__768 {
	  	height: 350px;
	  	width: auto;
	  	max-width: unset;
	  	float: right;
  	  margin: 0 -170px 0 0;
	  }

	  @include MQ__mobile--large {
	  	height: 260px;
	  }
	}

	.text {
		position: absolute;
	  left: 0;
	  right: 0;
	  top: 50px;
	  margin: auto;
	  position: absolute;
	  left: 45%;
	  -webkit-transform: translateX(-50%);
	  transform: translateX(-45%);
	  width: 100%;
	  max-width: 1162px;
	  padding: 0 13px;

		// @include MQ__1024 {
		// 	top: 30px;
		// }		

		@include MQ__tablet--small {
			transform: none;
			left: 0;
			text-align: left;
			max-width: 350px;
			right: unset;
		}								

		h2 {
			font-size: 61px;
			width: 100%;
			max-width: 385px;
			margin-bottom: 22px;
			color: $white;
			font-weight: 600;
			letter-spacing: -1px;

			@include MQ__1200 {
				max-width: unset;
			}

			// @include MQ__1024 {
			// 	font-size: 45px;
			// 	margin-bottom: 5px;
			// }

			@include MQ__mobile--medium {
				font-size: 34px;
			}
		}

		p, h1 {
			font-family: Lato,sans-serif;
			color: $white;
		  max-width: 435px;
		  line-height: 1.2;
		  font-size: 24px;
		  font-weight: 300;
		  margin-left: 3px;
		  letter-spacing: 0px;

		  @include MQ__1200 {
				max-width: unset;
			}

			// @include MQ__768 {
			// 	display: none;
			// }

			// @include MQ__1024 {
			// 	font-size: 18px;
			// }

			u {
				text-decoration: none;
				border-bottom: 1px $white dashed;
				font-weight: 700;
			}
		}

	}
}

.container.home-filters {
  position: absolute;
  z-index: 1;
  background: $black--trans;
  max-width: 1154px !important;
  left: -60px;
  right: 0;
  bottom: 89px;
  padding: 24px 50px 22px 32px !important;
  border-radius: 7px;

  @include MQ__1200 {
  	left: 0;
  	bottom: 0;
  	max-width: unset !important;
  	background: $spring;
  	border-radius: 0;
  }

  @include MQ__1024 {
		width: 100% !important;
		max-width: 100% !important;
		bottom: 0;
		border-radius: 0;
	}

	@include MQ__768 {
		padding: 20px !important;
		position: static;
		background: $spring;
	}
}

.container.inner-filters {
  position: relative;
  z-index: 1;
  max-width: 1213px;
  padding: 0;
  

  @include MQ__768 {
		padding: 10px 0 !important;
	}

	.inner-filter-wrap {
	  width: 100%;
	  max-width: 1154px;
	  float: left;
      padding: 20px 0 20px 10px;
  	border-radius: 7px;
	  // background: $black--trans;

	  @include MQ__768 {
	  	padding: 20px 15px;
	  }
	}

	.titles {
		margin-bottom: 31px;

		@include MQ__768 {
			flex-direction: column;
		  align-items: flex-start !important;
		}
	}

	h1 {
		@include MQ__768 {
			width: 100%;
			margin-bottom: 10px;
		}
	}

  p {
  	color: $white !important;

  	@include MQ__1200 {
  		color: $black;
  	}
  }
}