footer {
	width: 100%;
	padding: 0;
	float: left;
	border: 0;

	.footer-one {
		background: $zuccini;

		.container {
			display: flex;
		  align-items: stretch;
		  justify-content: flex-start;
		  padding: 0 20px !important;
    	max-width: 1231px;

		  @include MQ__mobile--large {
		  	flex-direction: column;
		  	padding: 0 !important;
		  }
		}

		p {
			color: $white;
			font-size: 22px;

			@include MQ__tablet--small {
				font-size: 17px;
			}

			&.int {
				font-family: $font-family--primary;
				width: 210px;
				background: #0f6634;  
				margin: 0;
				padding: 19px 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 0 1 auto;
				font-weight: 500;
				font-size: 21px;

				@include MQ__mobile--large {
			  	width: 100%;
			  	padding: 15px;
			  	justify-content: flex-start;

			  }
			}

			&.rest {
				margin: 18px 0 15px 29px;
			  font-size: 19px;
			  letter-spacing: 0.4px;

			  @include MQ__mobile--large {
			  	margin: 15px;
			  	font-size: 15px;
				}

				a {
					font-weight: bold;
					color: $white;
					text-decoration: none;
				}
			}
		}
	}

	.footer-two {
		background: $birch;
		padding: 18px 0 10px;

		@include MQ__mobile--large {
	  	background: $white;
		}

		.container {
			display: flex;
  		align-items: stretch;
  		padding: 0 37px !important;

  		@include MQ__mobile--large {
		  	padding: 0 15px !important;
		  }
		}

		.left {
			width: calc(100% - 245px);
			float: left;
			display: flex;
			align-items: center;
			padding-right: 30px;

			@include MQ__tablet--small {
		  	width: calc( 100% - 95px);
		  }

		}

		.right{
			float: right;
		  display: flex;
  		align-items: center;
  		width: 245px;

  		@include MQ__tablet--small {
		  	width: 95px;
		  }

  		p {
  			margin-right: 10px;

  			@include MQ__tablet--small {
			  	display: none;
			  }
  		}

  		img {
  			@include MQ__tablet--small {
  				display: none;
  			}
  		}

  		img.mobile {
  			display: none;

  			@include MQ__tablet--small {
  				display: block;
  			}
  		}
		}
		
		p {
			color: $white;
			margin: 0;
			font-size: 13px;
  		letter-spacing: 0.5px;

  		@include MQ__mobile--large {
  			color: $black;
  			font-size: 14px;
  		}

			a {
				color: $white;

				@include MQ__mobile--large {
	  			color: $black;
	  		}
			}
		}
	}
}