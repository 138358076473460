.view-product-tabs-displays,
.view-uc-catalog {
	width: 100%;
	float: left;

	.view-header {
		margin: 0 0 36px 0;
		h3 {
			font-size: 34px;

			span {
				font-size: 16px;
  			margin-left: 3px;

  			@include MQ__mobile--large {
  				width: 100%;
  				float: left;
  			}
			}
		}
	}


	.view-content {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.views-row {
		width: 165px;
		float: left;
		text-align: center;
		margin-bottom: 20px;
		margin-right: 34px;
		border: 1px solid $bonjour;

		@include MQ__768 {
			width: calc(50% - 30px);
			margin: 0 15px 35px;
		}

		@include MQ__mobile--large {
			width: calc(50% - 10px);
			margin: 0 5px 0;

		}

		@include MQ__mobile--small {
		  width: 100%;
  		margin: 0;
		}
	}

	.main {
		width: 100%;
		float: left;
	}

	.image, .buttons {
		width: 100%;
		float: left;
		margin-bottom: 0;
	}

	.buttons {
		width: 100%;
		float: left;
		margin-bottom: 0;
		display: flex;
	  align-items: stretch;
	  flex-direction: row;
	  justify-content: center;
		
		a {
			width: 60px;
			float: left;
			display: flex;
		  align-items: center;
		  justify-content: center;
		  font-weight: 600;
		  padding: 2px 12px;

			@extend %button-grey;

			@include MQ__768 {
				width: 80px;
			}

			@include MQ__mobile--large {
			  width: 35%;
			}
		}

		form {
			width: 105px;
			float: left;

			@include MQ__768 {
				width: 145px;
			}

			@include MQ__mobile--large {
			  width: 65%;
			}
		}

		input {
			width: 100%;
			float: left;
			font-weight: 600 !important;

			@extend %cart-button;
		}
	}

	.rest {
		width: 100%;
		float: left;
		padding: 15px;

		p {
			width: 100%;
			float: left;
			letter-spacing: 0;
		  	line-height: 20px !important;
	  		margin: 5px 0 !important;
	  		font-size: 15px;
	  		font-weight: 400;
		}

		span {
			width: 100%;
			float: left;
			font-size: 15px;
	  		font-weight: bold;
			
		}
	}

	img {
		width: 100%;
		float: left;
	}
}

.view-uc-catalog {
	
	.views-row {
		width: 277px;
		margin: 0 10px 30px;

		@include MQ__768 {
			width: calc(33.333% - 30px);
			margin: 0 15px 35px;
		}

		@include MQ__mobile--large {
			width: calc(50% - 10px);
			margin: 0 5px 15px;
		}

		@include MQ__mobile--small {
		  width: 100%;
  		margin: 0 0 35px;
		}
	}

	.buttons {
		a {
			width: 80px;
		}

		form {
			width: 200px;

		}
	}
}