.node-type-product {

	.view-product-tabs-displays {
		.views-row {
			width: 213px;
			margin-right: 25px;
		}

		.buttons form,
		.buttons a {
		    width: 50%;
		}

		.rest p:nth-child(1) {
		    width: 100%;
		    float: left;
		    font-size: 15px !important;
		    font-weight: 700 !important;
		    letter-spacing: 0 !important;
		    line-height: 20px!important;
		    margin: 5px 0!important;
		}

		.rest p.price {
		    width: 100%;
		    float: left;
		    letter-spacing: 0 !important;
		    line-height: 20px!important;
		    margin: 5px 0!important;
		    font-size: 15px !important;
		    font-weight: 400 !important;
		}

		.rest span {
		    
		}
	}

	#cboxClose:last-of-type {
	    display: none;
	}

	#page-content {

		@include MQ__tablet {
    	padding-top: 0;
  	}
	}

	#main-content {
		margin-top: 70px;

		@include MQ__tablet {
			margin-top: 0;
		}
	}
}

.product-top {
	margin-top: 20px;
	margin-bottom: 0px;
  padding-top: 17px;
  padding-bottom: 45px;
	background: $spring;

	@include MQ__large {
		margin-top: 0;
		padding-top: 0;
	}

	.container {
		background: $white;
		padding: 20px 34px 14px 20px !important;
		border-radius: 5px;
		max-width: 1215px;

		@include MQ__large {
			display: flex;
			flex-direction: column-reverse;
		  align-items: center;
		}

		@include MQ__tablet {
			padding: 0px 30px 14px !important;
		}

		@include MQ__mobile--large {
			padding: 0 15px 20px !important;
		}
	}

	.tabs {
		width: 100%;
		float: left;
	}
}

.prod-images {
	width: 600px;
	float: left;

	@include MQ__1200 {
		width: 450px;
	}

	@include MQ__large {
		width: 100%;
		max-width: 600px;
	}

	.owl-carousel {
		border: 0;
		padding: 0;
	  height: 400px;
  	overflow: hidden;
  	margin-bottom: 17px !important;

  	.owl-buttons div {
			opacity: 1 !important;
		}

  	@include MQ__large {
  		height: auto;
  	}

  	.item > span {
  		display: flex !important;
  		align-items: center !important;
  		justify-content: center !important;
  	}

		img {
			max-width: 100%;
			height: auto;
			width: auto;

			// &.zoomImg {
			// 	display: none !important;
			// }
		}
	}

	img.tp-prod-strip {
		width: 100%;
		height: auto;
		max-width: 600px;
	}
}

.prod-info {
	width: calc(100% - 600px);
	float: left;
	padding-left: 33px;
	margin-top: 17px;

	@include MQ__1200 {
		width: calc(100% - 450px);
	}

	@include MQ__large {
		width: 100%;
		padding: 0;
		margin-bottom: 30px;
	}

	h1 {
		color: $birch;
		font-size: 36px;
		margin-bottom: 0;
		color: $birch;
    width: calc(100% - 80px);
    line-height: 1;
    font-weight: 600;

  	span {
  		font-size: 18px;
			margin-bottom: 24px;
	  	margin-top: 10px;
	  	letter-spacing: -0.6px;
	  	width: 100%;
	  	display: block;
	  	font-weight: 500;
  	}

  	@include MQ__1200 {
  		clear: left;
  		float: left;
  	}

		@include MQ__large {
			clear: both;
		}

		@media screen and (max-width: 410px) {
			width: 100%;
		}
	} 

	img {
		float: right;
		margin-top: 3px;
		margin-right: 19px;

  	@include MQ__large {
  		position: absolute;
    	right: 0;
  	}

  	@include MQ__mobile--medium {
  		display: none;
  	}
	}
}

.prod-price-block {
	width: 100%;
	float: left;
	background: $grey;
	border-radius: 7px;
	
	.contain {
		width: 100%;
		float: left;
	  padding: 27px 30px 33px;

	  @include MQ__mobile--large {
	  	padding: 20px;
	  }
	}

	p {
		color: $white !important;
		font-size: 16px !important;

		@include MQ__mobile--large {
	    font-size: 15px !important;
	    line-height: 1.2 !important;
	  }
	}

	p.price {
		font-size: 20px !important;
		border-bottom: 1px solid $white;
		padding-bottom: 12px !important;
		margin-bottom: 21px !important;

		span {
			font-size: 33px !important;
  		margin-left: 4px;
		}

		&.basic {
			font-size: 0 !important;
		}
	}

	.info {
		margin-bottom: 23px;

		&.delivery p:first-of-type {
			font-size: 18px !important;
			letter-spacing: -0px !important;
		}

		&.lead-time {
			margin: 0;
		}

		p {
			margin-bottom: 0 !important;
			letter-spacing: -0.1px !important;

			&:first-child {
				margin-bottom: 5px !important;
		    letter-spacing: 0.8px !important;
			}
		}

		span {
			color: $fuel
		}

		a {
			color: $fuel;
			text-decoration: underline;
		}
	}

	.buttons_added {
		width: 100%;
		float: left;
		background: $black;
	  border-radius: 7px;
	  border-top-left-radius: 0;
	  border-top-right-radius: 0;

	  .contain {
	    padding: 22px 29px 20px 29px;

	  	@include MQ__tablet {
				padding: 10px;
			}
	  }

	  form .attributes {
  	  width: calc(100% - 162px);

  	  @include MQ__mobile--large {
  	  	width: 100%;
  	  }

  	  select {	
		    background: url(/sites/barjo.co.uk/files/arrow-down.png) no-repeat #fff;
		    background-position: 95% 55%;
		    width: 100% !important;
		    border: 0;
		    margin: 0;
		    width: 227px;
		    color: #333;
		    padding: 10.5px 13px;
		    font-size: 16px!important;
		    font-weight: 400;
      	-webkit-appearance: none!important;

		    @include MQ__mobile--large {
		    	font-size: 16px !important;
		    }	
  	  }
	  }

	  div#edit-actions {
	  	margin-left: 12px;

	  	@include MQ__mobile--large {
  	  	margin: 0;
  	  }

	  	input {
	  		@extend %cart-button-lg;

				color: $black;
				width: 180px;
				padding: 11px 15px;
				float: left;
				border: 0;
				font-size: 18px !important;
			  font-weight: 600 !important;
			  letter-spacing: 0px;
	  		max-width: 162px !important;
	  		padding: 13px 10px;
	  	}
	  }
	}
}

.tabs {
	background: $spring;

	@include MQ__tablet--small {
		display: none;
	}

	.container {
		padding: 0 41px;

		@include MQ__tablet {
			padding: 0 15px;
		}

		@include MQ__tablet--small {
			padding: 0;
		}

	}
	ul.nav-tabs {
		width: 100%;
		border: 0;
	  display: flex;
    align-items: stretch;
    flex-direction: row;
	  flex-wrap: nowrap;
		align-items: flex-end;

		@include MQ__tablet--small {
			flex-direction: column;
		}

		li {
			border-radius: 0;

			@include MQ__tablet {
		    flex: 1 0 auto;
			  align-items: center;
			  display: flex;
			}

			@include MQ__tablet--small {
				width: 100%;
			}

			a {
			  display: flex;
  			align-items: center;
				color: $white;
				background: $jewel;
				text-transform: none;
				font-weight: 500 !important;
				border-right: 1px solid $white;
				border-top: 0 !important;
	      padding: 19px 26px 17px;
				font-weight: 600;
			  font-size: 17px;
			  letter-spacing: 0px;
			  border-radius: 0;
			  margin: 0;

				@include MQ__tablet {
					width: 100%;
					font-size: 14px;
					padding: 16px 20px !important;
				}

				@include MQ__tablet--small {
					border-radius: 0;
					border: 0;
					margin: 0;
				}
			}

			&.accessories a {
		    padding: 19px 28px 19px 24px;
			}

			&.desc a {
		    padding: 18px 34px 19px 22px;
			}

			&.fitting a {
				padding: 15px 24px 16px;
			}

			&.related a {
			  padding: 18px 24px 21px;
			}	

			&.active, &:hover {
				a {
					outline: 0 !important;
					border-top: 0 !important;
					color: $white;
					background: $zuccini;

					@include MQ__tablet--small {
						border-radius: 0;
						border: 0;
						margin: 0;
					}
				}
			}
		}

		img {
			float: left;
  		margin-right: 10px;
  		height: auto;
  		width: auto;

  		@include MQ__tablet {
				height: 20px;
			}
		}
	}
}

.tab-content {
	border: 0;
	padding: 31px 20px 30px 11px;

	@include MQ__tablet {
		padding-left: 0;
	}

	@include MQ__tablet--small {
		width: 100%;
		float: left;
	}

	@include MQ__768 {
		padding: 0;
	}

	h3 {
		font-size: 34px;
		margin: 0 0 36px;
	}

	> .tab-pane {
		@include MQ__tablet--small {
			width: 100%;
			float: left;
			margin: 0 0 30px;
			display: block;
			opacity: 1;

			&:last-of-type {
				margin: 0;
			}
		}
	}
}

div#reviews-tab-2.tab-pane p {
    font-size: 18px;
    letter-spacing: .1px;
    padding-left: 5px;
    margin-bottom: 20px;
    line-height: 26px;
}

.product-further {
	background: $white;
	border-top: 10px solid $zuccini;

	.container {
		padding-top: 20px !important;

		@include MQ__tablet {
			padding: 20px 15px 0px !important;
		}
	}

	.left {
		width: calc(100% - 443px);
		float: left;

		@include MQ__1024 {
			  width: calc(100% - 300px);
		}

		@include MQ__768 {
			width: 100%;
		}
	}

	.right {
		width: 389px;
	  float: left;
	  margin-left: 48px;

		@include MQ__1024 {
			width: 250px;
		}

		@include MQ__768 {
			width: 100%;
			margin: 0;
		}
	}
}

.product-sidebar {
	> div {
		width: 100%;
		float: left;
		margin-bottom: 30px;
		padding-top: 18px;

		@include MQ__tablet--small {
			margin-bottom: 0;
		}
	}

	.need-help {

		.text {
			width: calc(100% - 130px);
			float: left;
			padding-right: 30px;

			@include MQ__1024 {
				width: 100%;
				padding: 0;
			}
		}

		h3 {
			font-size: 35px;
			color: $birch;
			margin-bottom: 18px;
	    font-weight: 500;
	    margin-top: 3px;
	    letter-spacing: -0.5px;
		}

		p {
			width: 100%;
			max-width: 200px;
		  line-height: 22px !important;
		  margin-bottom: 26px !important;

		  @include MQ__1024 {
				max-width: unset;
			}
		}

		a {
			font-size: 22px;
			font-weight: 900;
			color: $jewel;
		}

		img {
			width: 130px;
			height: auto;
			float: right;
			margin-top: -70px;

			@include MQ__1024 {
				display: none;
			}
		}
	}

	span {
		width: 34px;
    float: left;
    text-align: center;
    margin-bottom: 0;
    margin-right: 6px;
	}

	img {
		float: none;
		margin: 0 auto;
	}

	.big-cta img {
		width: 100%;
		height: auto;

		@include MQ__768 {
			display: none;
		}
	}
}

#cboxOverlay {
  background: $black !important;
}

#lead-time-content {
	padding: 37px;

	img {
		width: 51px;
	  float: left;
	  height: auto;
	  margin-top: 7px;
	}

	h2 {
	  width: calc(100% - 78px);
	  float: left;
	  margin-left: 25px;
	  font-size: 24.5px;
	  padding-right: 60px;
	  letter-spacing: -0.7px;
	  line-height: 33px;
	  margin-bottom: 33px;
	}

	p {
		width: 100%;
		float: left;
	  line-height: 1.5;
	}

	button.close {
		float: left;
		position: static;
	    font-size: 20px !important;
    	opacity: 1 !important;
	    font-family: Lato,sans-serif;
		font-weight: 300 !important;
		line-height: normal;
		letter-spacing: normal;

		@extend %button-stnd
	}
}