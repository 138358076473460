.page-node-3462 {

	.container {
		padding: 0 25px;

	 	@include MQ__tablet--small {
	    padding: 0 15px;
	  }
	}

	#page-header { 
		margin-bottom: 19px;

		@include MQ__tablet--small {
	    padding: 10px 0;
	  }
	}

	#content {
		.left {
			width: calc(100% - 420px);
			float: left;
		  background: url(/sites/barjo.co.uk/files/boris.jpg) no-repeat transparent;
	  	background-position: 93% 0;
	  	padding-right: 128px;

	  	@include MQ__1200 {
	  		background: transparent;
	  		padding-right: 40px;
	  	}

	  	@include MQ__large {
	  		width: calc(100% - 280px);
	  	}

	  	@include MQ__tablet--small {
	  		width: 100%;
	  		padding: 0;
	  	}

			h2 {
				font-size: 26.5px;
		 		font-weight: 600;
		 		font-family: $font-family--secondary;
		 		margin-bottom: 30px;

		 		a {
		 			margin-bottom: 6px;
	  			display: inline-block;
	  			text-decoration: underline;
		 		}
			}

			p {
				letter-spacing: 0px;
			  width: 100%;
			  max-width: 540px;
			  line-height: 30px;

			  @include MQ__1200 {
		  		max-width: unset;
		  	}
			}

			.directions {
			  background: url(/sites/barjo.co.uk/files/marker-fuel.png) no-repeat transparent;
			  background-position: left 81%;
			  padding-left: 45px;
			  padding-top: 20px;
			  margin-bottom: 42px;
			  width: 100%;
			  float: left;

			  p {
			  	max-width: unset;
			  	margin-bottom: 0;
			  }
			}

			.accordion {
	  		margin-bottom: 53px;
		    border-bottom: 2px solid $bonjour;
			}

			.accordion-header {
				border-top: 2px solid $bonjour;
			  border-bottom: 2px solid $bonjour;
			  padding: 15px 0 15px 11px;

			  &:hover {
			  	cursor: pointer;
			  }

			  &:first-child {
			  	border-top: 4px solid $bonjour;
			  }

			  &:last-child {
			  	border-bottom: 4px solid $bonjour;
			  }
			}

			.accordion-content {
				display: none;
				width: 100%;
				float: left;
				margin: 0 0 20px 0;
		    font-size: 16px;
		    line-height: 1;
		    font-weight: normal;
		    color: #332b28;
        letter-spacing: 0px;
        line-height: 24px;
        border: 0;
			}

		}

		.right {
			width: 420px;
			float: left;
			border-left: 4px solid $bonjour;
			padding-left: 36px;
			padding-right: 13px;


			@include MQ__large {
	  		width: 280px;
	  	}

	  	@include MQ__tablet--small {
	  		width: 100%;
	  		padding: 0;
	  		border: 0;
	  		margin-bottom: 30px;
	  	}

  		.links {
  			display: none !important;
  		}
		}
	}
}

div.error, table tr.error {
	color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    border-radius: .25rem;
	margin-bottom: 15px;
    font-size: 17px;
}