div.dropdown {
	background: $jewel;
	transition: 0.2s ease all;

	a {
		font-size: 14px;
	}

	&:hover {
		background: #248e51;

		@include MQ__tablet--small {
			background: transparent;
		}
	}

	@include MQ__tablet--small {
		position: absolute;
		right: 120px;
		background: transparent;
		z-index: 99999;
		right: 90px;
		top: 10px;
	}

	&.basket {
		border-left: 1px solid $zuccini;

		@include MQ__tablet--small {
			border: 0;
			margin-right: auto;
			right: 50px;
			top: 10px;
		}
	}

	a {
		transition: 0.2s ease all;

		@include MQ__tablet--small {
		  padding: 17px !important;
		}
	}

	a:hover {
		text-decoration: none;
		// color: $white;
	}

	&.open a {
		color: $white;
		text-decoration: none;
	}


	a#dropdownUserMenu {
		background: url(/sites/barjo.co.uk/files/my-account.png) no-repeat transparent;
		background-size: 13px !important;
	  background-position: 13px 50% !important;
	  padding: 10px 12px 5px 34px;
		font-size: 14px !important;

		&:hover {
			color: #fff;
		}

		@include MQ__1024 {
	  	font-size: 12px !important
	  } 

		@include MQ__tablet--small {
	  	background: url(/sites/barjo.co.uk/files/customer-icon-mobile.png) no-repeat transparent;
			font-size: 0px !important;
			background-position: 50% !important;
			background-size: 23px !important;

			&:hover {
				background-size: 23px !important;
			}
		}

		@include MQ__mobile--large {
			font-size: 0px !important;
			padding: 10px 15px;
			background-position: 50% !important;
		}
	}

	a#dropdownAjaxCart {
		background: url(/sites/barjo.co.uk/files/cart-new.png) no-repeat transparent;
		background-position: 11px 50% !important;
	  padding: 10px 14px 5px 40px;
	  background-size: 19px !important;
	  font-size: 14px !important;
	  color: $white !important;
	  text-decoration: none !important;

	 //  &:hover {
		// 	background-size: 22px !important;
		// }


	  @include MQ__1024 {
	  	font-size: 12px !important
	  } 

	  @include MQ__tablet--small {
	  	background: url(/sites/barjo.co.uk/files/cart-icon-mobile_0.png) no-repeat transparent;
			font-size: 0px !important;
			background-position: 50% !important;
			position: absolute;
			right: 0;
			background-size: 23px!important;

			&:hover {
				background-size: 23px !important;
			}
		}

		@include MQ__mobile--large {
			font-size: 0px !important;
			padding: 10px 15px;
			background-position: 50% !important;
		}
	}
}